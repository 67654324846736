<template>
  <div>
    <prozess-search-dropdown
      v-model="data"
      :placeholder="$t(placeholder)"
      :loading="loading"
      :can-handle-new-item="canHandleNewItem"
      :options="items"
      icon="UserIcon"
      :error="error"
      :hint="hint"
      :disabled="disabled"
      @search="handleSearch"
      @clear="handleClear"
      @blur="handleBlur"
    />
  </div>
</template>

<script>
import employeeService from '@/services/employee'

export default {
  props: {
    autocompleteApi: {
      type: Function,
      default: search => employeeService.searchEmployee(search.trim()),
    },
    canHandleNewItem: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      default: 'Default Admin',
      type: String,
    },
    label: {
      default: null,
      type: String,
    },
    value: {
      default: null,
      type: [String, Number, Object],
    },
    hint: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: this.value,
      items: [],
      loading: false,
    }
  },
  watch: {
    data() {
      this.$emit('input', this.data)
    },
    value(data) {
      this.data = data
    },
  },
  methods: {
    handleClear() {
      this.items = []
      this.$emit('input', null)
    },
    handleBlur() {
      this.items = []
    },
    async handleSearch(search) {
      if (search.trim() === '') return
      this.loading = true
      const { response } = await this.$async(this.autocompleteApi(search))
      this.items = response.data.map(item => ({
        label: `${item.firstName || ''} ${item.lastName}`,
        // selectedLabel: item.firstName || this.$t('(No first name)'),
        value: item,
      }))
      this.loading = false
    },
  },
}
</script>
